<template>
    <div class="page-job-editar my-4">
        <div class="row">
            <div class="col-md-12">
                <h4> JOB | <strong>CADASTRAR</strong></h4>
            </div>
        </div>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">

            <div class="row">
                <div class="col">
                    <b-form-group id="input-group-2" label="project" label-for="project" class="mb-3">
                        <b-form-input
                            id="project"
                            v-model="job.project"
                            name="project"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="input-group-2" label="title" label-for="title" class="mb-3">
                        <b-form-input
                            id="title"
                            v-model="job.title"
                            name="title"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b-form-group id="input-group-2" label="description" label-for="description" class="mb-3">
                        <b-form-textarea
                            id="description"
                            v-model="job.description"
                            name="description"
                            required
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b-form-group id="input-group-2" label="client" label-for="client" class="mb-3">
                        <b-form-input
                            id="client"
                            v-model="job.client"
                            name="client"
                        ></b-form-input>
                    </b-form-group>
                    <img :src="job.client" v-if="job.client" class='img-job rounded'>
                </div>
                <div class="col">
                    <b-form-group id="input-group-2" label="link_image" label-for="link_image" class="mb-3">
                        <b-form-input
                            id="link_image"
                            v-model="job.link_image"
                            name="link_image"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <img :src="job.link_image" v-if="job.link_image" class='img-job rounded'>
                </div>
            </div>
            <div class="row">
                <div class="mt-3">
                    <b-button type="submit" variant="primary">Salvar</b-button>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
import JobService from "../../../services/JobService";
import Utils from "../../../utils/utils.js";

export default {
    name: "Job",
    data() {
        return {
            job: {
                project: '',
                client: '',
                title: '',
                description: '',
                link_image: '',
            },
            show: true
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            this.jobService.new(this.job)
            .then(() => {
                this.Utils.success('Job cadastrado com sucesso!');
                this.$router.push({name:'painel.job'});
            })
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.job.icon = ''
            this.job.title = ''
            this.job.description = null
            this.job.link = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    created() {
        this.Utils = new Utils();
        this.jobService = new JobService();
    },
    mounted() {
    }
}
</script>

<style scoped>
.img-job{
    max-height: 200px;
}
</style>
